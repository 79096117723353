import { Button, Paper, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Carousel from 'react-material-ui-carousel';
import './Banner.css'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { HashLink } from 'react-router-hash-link';

const Banner = () => {
    function Item(props) {
        // style={{minHeight: props.minHeight, maxHeight: props.maxHeight}}
        return (
            <Paper>
                <div className='banner-container'>
                    <img src={require(`../../../Assets/Banners/${props.item.img}.jpg`)} alt=""/>
                </div>

            </Paper>
        )
    }
    const wb_items = [
        {
            img: "WB_carousel1"
        },
        {
            img: "WB_carousel2"
        },
        {
            img: "WB_carousel3"
        },
        {
            img: "WB_carousel4"
        },
        {
            img: "WB_carousel5"
        },
        {
            img: "WB_carousel6"
        },
        {
            img: "WB_carousel7"
        },
    ]
    const mb_items = [
        {
            img: "MB_carousel1"
        },
        {
            img: "MB_carousel2"
        },
        {
            img: "MB_carousel3"
        },
        {
            img: "MB_carousel4"
        },
        {
            img: "MB_carousel5"
        },
        {
            img: "MB_carousel6"
        },
        {
            img: "MB_carousel7"
        }
    ]
    const [screenSize, setScreenSize] = useState();
    let size = window.innerWidth;
    useEffect(() => {
        setScreenSize(size);
        window.addEventListener("resize", () => setScreenSize(size));
    }, []);
    return (
        <div>
            <Carousel autoPlay>
                {
                    size > 900 ? wb_items.map((item, i) => <Item key={i} item={item} minHeight='70vh' maxHeight='70vh' />) : mb_items.map((item, i) => <Item key={i} item={item} minHeight='60vh' maxHeight='60vh' />)
                }
            </Carousel>
        </div>
    );
};

export default Banner;