import React, { useEffect, useState } from 'react'
import { Avatar, Box, Container, Divider, Grid, Typography, Chip, Stack, InputLabel, Paper, TextField, Select, MenuItem, experimentalStyled, Button, FormControl, FormHelperText } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import MuiPhone from '../../HelperComponents/InternationalPhoneInput';
import dayjs from 'dayjs';
import { POST, GET } from '../../../Common/utils';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

export default function Appointment(props) {
    const [slots, setSlots] = useState([]);
    const [slotIndex, setSlotIndex] = useState(-1);
    const [bookedSlot, setBookedSlot] = useState(-1);
    const [expertsData, setExpertsData] = useState([]);
    const [doctorsData, setDoctorsData] = useState([]);
    const [profileData, setProfileData] = useState({});
    const [open, setOpen] = useState(false);
    const [erroeMessage, setErrorMessage] = useState({});
    const [appointmentBody, setAppointmentBody] = useState({
        doctorId: '',
        doctorName: '',
        category: '',
        patientName: '',
        patientMobileNumber: '',
        patientMobileIsdCode: '',
        date: '',
        time: ''
    });
    const { state } = useLocation();
    const propsData = state?.doctorData;
    const [isChipClicked, setIsChipClicked] = useState(false);
    const navigate = useNavigate();
    const [country, setCountry] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        getDoctorsData();
        if(propsData && Object.keys(propsData).length != 0) {
            handlePropsData();
        }
    }, []);

    const getDoctorsData = () => {
        return new Promise((resolve, reject) => {
            GET("FETCH_ALL_DOCTOR", '', (res) => {
                if(res.data.statusCode == 200) {
                    let responseData = res?.data?.data?.data;
                    setExpertsData(responseData);
                    setDoctorsData(responseData);
                }
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    const handlePropsData = () => {
        setAppointmentBody((prevState) => {
            return {
                ...prevState,
                category: propsData.category,
                doctorName: propsData.name,
                doctorId: propsData.uid,
            }
        });
        setProfileData(propsData);
    }

    const bookSlot = (item) => {
        let findIndex = slots.findIndex((data) => data == item);
        setSlotIndex(findIndex);
        setBookedSlot(item);
        setAppointmentBody((prevState) => {
            return {
                ...prevState,
                time: item,
            }
        });
        setIsChipClicked(!isChipClicked);
    }
    const handleCategory = (value) => {
        if(expertsData.length > 0) {
            let doctors = expertsData?.filter((data) => {
                return data.category == value
            });
            setDoctorsData(doctors);
            setAppointmentBody((prevState) => {
                return {
                    ...prevState,
                    category: value,
                }
            });
        }
    }
    const handleDoctor = (value) => {
        if(expertsData.length > 0) {
            let doctors = expertsData?.find((data) => {
                return data.name == value
            });
            setProfileData(doctors);
            setAppointmentBody((prevState) => {
                return {
                    ...prevState,
                    category: doctors.category,
                    doctorName: value,
                    doctorId: doctors.uid
                }
            });
        }
    }

    const handleDate = (newValue) => {
        setAppointmentBody((prevState) => {
            return {
                ...prevState,
                date: dayjs(newValue).format('YYYY/MM/DD'),
            }
        });
        getSlots(dayjs(newValue).format('YYYY/MM/DD'));
    }

    const getSlots = (date) => {
        let reqData = `doctorId=${appointmentBody.doctorId}&startDate=${date}`
        return new Promise((resolve, reject) => {
            GET("FETCH_AVAILABLE_SLOTS", reqData, (res) => {
                if(res.data.statusCode == 200) {
                    let responseData = res?.data?.data[0].slots;
                    setSlots(responseData);
                }
                enqueueSnackbar(res.data.msg, { variant: res.data.statusCode == 200 ? 'success' : 'warning' });
                resolve(res);
            }, (err) => {
                enqueueSnackbar(err?.response?.data?.msg, { variant: 'warning' });
                reject(err);
            });
        })
    }

    const  handlePatientName = (e) => {
        const { value} = e.target;
        setAppointmentBody((prevState) => {
            return {
                ...prevState,
                patientName: value,
            }
        });
        // let error = validateForm(appointmentBody);
        // setErrorMessage(error);
    }

    const handlePatientMobileNumber = (mobileNo, country) => {
        setCountry(country);
        setAppointmentBody((prevState) => {
            return {
                ...prevState,
                patientMobileNumber: mobileNo,
                patientMobileIsdCode: `+${country.dialCode}`
            }
        });
    }

    function stringAvatar(name) {
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
        };
    }

    function stringToColor(string) {
        let hash = 0;
        let i;

        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        return color;
    }
    const validateForm = (body) => {
        const errors = {};
        if (!body.doctorName) {
            errors.doctorName = 'Doctor name is required';
        }
        if (!body.category) {
            errors.category = 'Specialization is required';
        }
        if (body.patientMobileNumber.length <= `+${country.dialCode}`.length) {
            errors.patientMobileNumber = 'Patient mobile number is required';
        }
        if (!body.patientName) {
            errors.patientName = 'Patient name is required';
        }
        if (!body.date) {
            errors.date = 'Date is required';
        }
        if(!isChipClicked) {
            errors.time = 'Slot selection is required';
        }
        return errors;
    }
    const handleSubmit = async(e) => {
        e.preventDefault();
        let error = validateForm(appointmentBody);
        setErrorMessage(error);
        if(Object.keys(error).length == 0) {
            let response = () => {
                return new Promise((resolve, reject) => {
                    POST('BOOK_APPOINTMENT', appointmentBody, (res) => {
                        if(res.data.statusCode == 200) {
                            navigate('/#home');
                        }
                        enqueueSnackbar(res?.data?.msg, { variant: res.data.statusCode == 200 ? 'success' : 'warning' });
                        resolve();
                    }, (err) => {
                        enqueueSnackbar(err?.response?.data?.msg, { variant: 'warning' });
                        reject();
                    });
                })
            }
            let responsedata = await response();
        } else {
            enqueueSnackbar('Please enter mandatory fields', { variant: 'warning' });
            setTimeout(() => {
                setErrorMessage({});
            }, 5000);
        }
    }

    return (
        <div>
            <Container sx={{ mt: '8rem', mb: '2rem' }}>
                <form onSubmit={handleSubmit}>
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Grid
                                container
                                sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    alignItems: "center",
                                    justifyContent: "center",
                                    mb: '2rem'
                                }}
                                columnSpacing={2}
                                rowSpacing={2}
                            >
                                <Grid item xs={12} md={6}>
                                    <InputLabel variant="standard" sx={{ mb: 1 }}>
                                        Specialization <span style={{color: '#E10600'}}>*</span>
                                    </InputLabel>
                                    <FormControl fullWidth error={erroeMessage.category ? true: false}>
                                    <Select
                                        labelId="specialization-label"
                                        id="specialization"
                                        size='small'
                                        fullWidth
                                        value={appointmentBody.category}
                                        onChange={(e) => handleCategory(e.target.value)}
                                    >
                                        {expertsData.length > 0 && expertsData.map((doctor) => (
                                            <MenuItem
                                                key={doctor._id}
                                                value={doctor.category}
                                            >
                                                {doctor.category}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{erroeMessage.category}</FormHelperText>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} md={6}>
                                    <InputLabel variant="standard" sx={{ mb: 1, textAlign: {xs: 'center', md: 'start'} }}>
                                        Doctor <span style={{color: '#E10600'}}>*</span>
                                    </InputLabel>
                                    <FormControl fullWidth error={erroeMessage.doctorName ? true: false}>
                                    <Select
                                        labelId="doctor-label"
                                        id="doctor"
                                        size='small'
                                        fullWidth
                                        value={appointmentBody.doctorName}
                                        onChange={(e) => handleDoctor(e.target.value)}
                                    >
                                        {doctorsData.length > 0 && doctorsData.map((name) => (
                                            <MenuItem
                                                key={name._id}
                                                value={name.name}
                                            >
                                                {name.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    <FormHelperText>{erroeMessage.doctorName}</FormHelperText>
                                    </FormControl>
                                </Grid>
                            </Grid>
                            {
                                Object.keys(profileData).length != 0 ?
                                    <Grid
                                        container
                                        rowSpacing={2}
                                        columnSpacing={1}
                                        sx={{
                                            flexDirection: { xs: 'column', md: 'row' },
                                            alignItems: "center",
                                            justifyContent: "center"
                                        }}
                                    >
                                        <Grid item xs={12} md={5}>
                                            {/* {...stringAvatar(profileData.name)} */}
                                            <Avatar src={profileData.profilePic ? require(`../../../Assets/Doctors/${profileData.profilePic}.png`) : ""} sx={{ width: '6rem', height: '6rem' }}></Avatar>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <Typography variant='body1'><b>{profileData && profileData.name}</b></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Typography variant='body1'>Select Date  <span style={{color: '#E10600'}}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <FormControl error={erroeMessage.date ? true: false}>
                                                <DatePicker
                                                    label="Select"
                                                    format='DD-MM-YYYY'
                                                    disablePast
                                                    value={appointmentBody.date}
                                                    onChange={(newValue) => handleDate(newValue)}
                                                    open={open}
                                                    onOpen={() => setOpen(true)}
                                                    onClose={() => setOpen(false)}
                                                    slotProps={{
                                                        textField: {
                                                            size: 'small',
                                                            fullWidth: true,
                                                            onClick: () => setOpen(true)
                                                        },
                                                    }}
                                                />
                                                <FormHelperText>{erroeMessage.date}</FormHelperText>
                                                </FormControl>
                                            </LocalizationProvider>
                                        </Grid>
                                        <Grid item xs={12} md={5}>
                                            <Typography variant='body1'>Available Slots  <span style={{color: '#E10600'}}>*</span></Typography>
                                        </Grid>
                                        <Grid item xs={12} md={7}>
                                            <FormControl error>
                                                <Stack direction="row" flexWrap="wrap" useFlexGap spacing={1}>
                                                    {
                                                        slots && slots.map((slot, index) => {
                                                            return (
                                                                <Chip key={index} label={slot} onClick={() => bookSlot(slot)} color='warning' variant={slotIndex == index ? 'filled' : 'outlined'} />
                                                            )
                                                        })
                                                    }
                                                </Stack>
                                                <FormHelperText>{erroeMessage.time}</FormHelperText>
                                            </FormControl>
                                        </Grid>
                                    </Grid>
                                    :
                                    <></>
                            }
                        </Grid>
                        <Divider orientation="vertical" flexItem sx={{ mx: 3, borderRightWidth: 5 }} className=''></Divider>
                        <Grid item xs={12} md={5}>
                            <Box>
                                <Paper sx={{ p: 2, mt: {xs: 2, md: 0}, backgroundColor: '#fec32b' }} variant='outlined'>
                                    <Typography variant='body1' fontWeight="bold">Patient Info</Typography>
                                </Paper>
                            </Box>
                            <Paper sx={{ pt: 5, pb: 5, mt: 2 }} variant='outlined'>
                                <Grid
                                    container
                                    columnSpacing={1}
                                    rowSpacing={2}
                                    sx={{
                                        flexDirection: { xs: 'column', md: 'row' },
                                        alignItems: "center",
                                        justifyContent: "center"
                                    }}
                                >
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='body1'>Name: <span style={{color: '#E10600'}}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <TextField
                                            id="name"
                                            type="text"
                                            size='small'
                                            fullWidth
                                            value={appointmentBody.patientName}
                                            onChange={(e) => handlePatientName(e)}
                                            autoComplete="current-password"
                                            error={erroeMessage.patientName ? true: false}
                                            helperText={erroeMessage.patientName}
                                        />
                                    </Grid>
                                    <Grid item xs={12} md={4}>
                                        <Typography variant='body1'>Phone Number: <span style={{color: '#E10600'}}>*</span></Typography>
                                    </Grid>
                                    <Grid item xs={12} md={7}>
                                        <MuiPhone
                                            value={appointmentBody.patientMobileNumber}
                                            onChange={handlePatientMobileNumber}
                                            helperText={erroeMessage.patientMobileNumber}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                            {/* <Paper sx={{ p: 2, mt: 2 }} variant='outlined'>
                            <Typography variant='subtitle'>
                                <b>Appointment Info</b>
                            </Typography>
                            <Grid
                                container
                                columnSpacing={1}
                                rowSpacing={2}
                                sx={{
                                    flexDirection: { xs: 'column', md: 'row' },
                                    alignItems: "center",
                                    justifyContent: "center",
                                    pt: 3
                                }}
                            >
                                <Grid item xs={12} md={6}>
                                    <Typography variant='body1'>Date</Typography>
                                </Grid>
                                <Grid item xs={12} md={6} alignSelf='center'>
                                    <LocationOnIcon />
                                    <Typography variant='body1'>
                                        H.No. 6-3-347/8A, G2 , Sunny Residency, Dwarakapuri colony, Punjagutta, Hyderabad, Telangana 500082, INDIA.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Paper> */}
                        </Grid>
                    </Grid>
                    <Grid>
                        <Box sx={{ display: 'block', textAlign: 'center', mt: 5 }}>
                            <Button variant="contained" color='primary' type='submit'>
                                Book Appointment
                            </Button>
                        </Box>
                    </Grid>
                </form>
            </Container>
        </div>
    )
}
