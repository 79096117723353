import React from 'react';
import { Avatar, Box, Button, Container, Grid, Typography } from '@mui/material';
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";


const Doctors = (props) => {
    const { state } = useLocation();
    const doctorData = state.doctorData;
    const navigate = useNavigate();
    // console.log("props", state, doctorData);
    const navigateTo = (path, doctorData) => {
        navigate(`/${path}`, { state: { doctorData }});
    }
    return (
        <div id='doctors'>
            <Container sx={{ p: {xs: 5, md: 10}}}>
                {/* <Grid sx={{ padding: {xs: 5, md: 7}}}> */}
                    <Grid container>
                        <Grid item xs={12} md={3}>
                            <Box 
                                sx={{
                                    pt: 2, 
                                    pb: 2,
                                    display: { xs: 'flex', md: 'block'},
                                    flexDirection: { xs: 'column', md: 'row'},
                                    alignItems: "center",
                                    justifyContent: "center"
                                }}
                            >
                                <Avatar src={doctorData.profilePic ? require(`../../../Assets/Doctors/${doctorData.profilePic}.png`) : ""} sx={{ width: '9rem', height: '9rem', mb: 2}}/>
                                <Typography variant='h6' color='#fec32b'>
                                    { doctorData.name }
                                </Typography>
                                <Typography component='div' variant='body1' sx={{mt: 1}}>
                                    { doctorData.category }
                                </Typography>
                                <Box sx={{ display: 'block', marginTop: {xs: 3}}}>
                                    <Button variant="contained" color='primary' onClick={() => navigateTo('appointment', doctorData)}>
                                        Book Appointment
                                    </Button>
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={9}>
                            <Box>
                                <Typography component='div' variant='body1' sx={{whiteSpace: 'pre-line'}}>
                                    { doctorData.longDescription || doctorData.shortDescription }
                                </Typography>
                            </Box>
                        </Grid>
                    </Grid>
                {/* </Grid> */}
            </Container>
        </div>

    );
};

export default Doctors;