import { AppBar, Avatar, Box, Button, Menu, MenuItem, Toolbar, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import MenuIcon from '@mui/icons-material/Menu';
import './NavBar.css'
import { HashLink } from 'react-router-hash-link';
import BrandLogo from '../../../Assets/BrandLogo.jpg';
import IconButton from '@mui/material/IconButton';
import LoginDialog from '../../HelperComponents/LoginDialog';


const Navbar = () => {
    //navigation
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [open, setOpen] = React.useState(false);
    const [isLoggedIn, setIsLoggedIn] = React.useState(false);

    useEffect(() => {
        if (window.sessionStorage.getItem('xyz')) {
            setIsLoggedIn(true);
        }
    }, []);

    const handleClickOpen = () => {
        setOpen(true);
        setAnchorElNav(null);
    };

    const handleClose = () => {
        setOpen(false);
    };

    //open naviagation for small device
    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    // this is for close nav menu
    const handleCloseNavMenu = (event) => {
        setAnchorElNav(null);
    };

    return (
        <Box sx={{ mt: 8 }} >
            <AppBar position="fixed" color="" sx={{ top: 0, bottom: 'auto' }} >
                <Toolbar disableGutters>
                    <MenuItem
                        bgcolor="primary"
                        component={HashLink}
                        smooth to='/'
                        sx={{ display: { xs: 'flex', md: 'flex', justifyContent: 'start' } }}
                    >
                        <Box
                            component="img"
                            sx={{
                                height: 64,
                                borderRadius: 4
                            }}
                            alt="Your logo."
                            src={BrandLogo}
                        />
                    </MenuItem>

                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex', justifyContent: 'end' } }}>

                        {/*-------- large screen navigation-------- */}

                        <HashLink
                            className="text-style"
                            smooth to='/#home'>
                            <Button

                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Home
                            </Button>
                        </HashLink>

                        <HashLink
                            className="text-style"
                            smooth to='/#why-us'>
                            <Button
                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Why to choose us
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/#doctors'>
                            <Button

                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Doctors
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/#services'>
                            <Button
                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Services
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/#home'>
                            <Button
                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >About Us
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/blog' target='_blank'>
                            <Button
                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Blog
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/#contact-us'>
                            <Button

                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Contact Us
                            </Button>
                        </HashLink>
                        <HashLink
                            className="text-style"
                            smooth to='/appointment'>
                            <Button

                                sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                            >Appointment
                            </Button>
                        </HashLink>
                        {
                            window.sessionStorage.getItem('xyz') ?
                                <HashLink
                                    className="text-style"
                                    smooth to='/profile'>
                                    <Box
                                        sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                                    ><Avatar />
                                    </Box>
                                </HashLink>
                                :
                                <Button
                                    sx={{ my: 2, mx: 1, color: 'Black', display: 'block' }}
                                    onClick={() => handleClickOpen()}
                                >Login
                                </Button>
                        }
                    </Box>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: { xs: 'end', md: 'none' }, p: 2 }}>
                        <IconButton
                            size="large"
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleOpenNavMenu}
                            color="inherit"
                            edge='end'
                        >
                            {
                                window.sessionStorage.getItem('xyz') ?
                                <Avatar /> : <MenuIcon />
                            }
                        </IconButton>
                        <Menu
                            id="menu-appbar"
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}

                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            {/*-------- small screen navigation-------- */}
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#home'
                            >Home
                            </MenuItem>

                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#why-us'
                            >Why to choose us
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#doctors'
                            >Doctors
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#services'
                            >Services
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#home'
                            >About Us
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/blog' target='_blank'
                            >Blog
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/#contact-us'
                            >Contact Us
                            </MenuItem>
                            <MenuItem
                                bgcolor="primary"
                                component={HashLink}
                                smooth to='/appointment'
                            >Appointment
                            </MenuItem>
                            {
                                window.sessionStorage.getItem('xyz') ?
                                <MenuItem
                                    bgcolor="primary"
                                    component={HashLink}
                                    smooth to='/profile'
                                >Profile
                                </MenuItem>
                                :
                                <MenuItem
                                    bgcolor="primary"
                                    onClick={() => handleClickOpen()}
                                >Login
                                </MenuItem>
                            }
                        </Menu>
                    </Box>
                    {/* Login/Register Dialog  */}
                    <LoginDialog open={open} handleClose={handleClose} />
                </Toolbar>
            </AppBar>
        </Box>
    );
};

export default Navbar;