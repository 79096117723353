import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import Login from './Login.js';
import Register from './Register.js';

const LoginDialog = (props) => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setValue(newValue);
    };


    return(
        <>
            {/* Login Dialog */}
            <Dialog 
                onClose={props.handleClose}
                aria-labelledby="customized-dialog-title"
                open={props.open}
                maxWidth="xs"
            >
                {/* <Box sx={{boxShadow: '0 0 0.5rem 0 #e5e5e5', backgroundColor: '#fec32b'}}>
                    <DialogTitle sx={{ m: 0, p: 2, color: '#fff' }} id="customized-dialog-title">
                        Login/Register
                    </DialogTitle>
                    <IconButton
                        aria-label="close"
                        onClick={props.handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[100],
                        }}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box> */}
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={value}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange}  centered aria-label="lab API tabs example">
                            <Tab label="Login" value="1" />
                            <Tab label="Register" value="2" />
                        </TabList>
                        <IconButton
                            aria-label="close"
                            onClick={props.handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: 'black'//(theme) => theme.palette.grey[100],
                            }}
                        > <CloseIcon /> </IconButton>
                        </Box>
                        <TabPanel value="1">
                            <Login handleClose={props.handleClose} />
                        </TabPanel>
                        <TabPanel value="2">
                            <Register handleClose={props.handleClose} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </Dialog>
        </>
    )
}

export default LoginDialog;