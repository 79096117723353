import { Box, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import './Whyus.css'


const Whyus = () => {

    useEffect(() => {
    }, []);

    return (


        <Box id='why-us' sx={{ bgcolor: '#fec32b', color: '#383738', p: 2, textAlign: "center" }}>
            <Grid container spacing={{xs: 2, sm: 2}} alignItems="center" justifyContent="center">
                <Grid item xs={12} sm={6}>
                    <Typography component='div' variant='h5'>
                        <b>Why to choose us</b>
                    </Typography>
                    <p className='text-style1'>
                        At Naari, we're here to make your work and personal life easier. We offer remote appointments with short wait times, and we have evening and weekend slots, so it's convenient for working couples and women.
                        <br></br><br></br>
                        Our team is easy to talk to and really cares about your feelings. We give you treatments that are both emotionally supportive and based on the latest medical knowledge.
                        <br></br><br></br>
                        We know that everyone is different. We'll explain the good and bad sides of your treatment options so you can choose what's best for you.
                        <br></br><br></br>
                        We make things easy for you by delivering your medicine to your door and doing blood tests at your home whenever we can. We won't slow things down.
                        <br></br><br></br>
                        We're open and honest about everything. We'll give you all the paperwork for your treatment so you can check it or get a second opinion.
                    </p>
                </Grid>
                <Grid item xs={12} sm={6}>
                <div className="video-responsive">
                    <iframe
                        src={`https://www.youtube.com/embed/4WXs3sKu41I`}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                    />
                </div>
                </Grid>
            </Grid>
        </Box>


    );
};

export default Whyus;