import { useState, useEffect } from 'react';
import { Avatar, Box, Container, Grid, Typography, Paper, Stack } from '@mui/material';
import { GET } from '../../../Common/utils';
import { useNavigate } from "react-router-dom";

const Services = () => {
    const [ourServices, setOurServices] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        response();
    }, []);

    const navigateToServiceDetails = (categoryData) => {
        navigate('/treatment', { state: { categoryData }});
    }

    const response = () => {
        return new Promise((resolve, reject) => {
            GET("FETCH_ALL_CATEGORIES", '', (res) => {
                if(res.data.statusCode == 200) {
                    let responseData = res?.data?.data?.data;
                    setOurServices(responseData);
                }
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    return (
        <Box id='services' sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: '#fec32b', mt: {xs: 3, md: 5}, p: 2, mb: 2,textAlign: "center"
        }}>
            {ourServices.length > 0 ?
                <Container maxWidth="xl">
                    <Typography sx={{ mt: 2, mb: 1 }} variant='h5'>
                        <b>Our Services</b>
                    </Typography>

                    <Grid container spacing={{xs: 2}}>
                        {
                            ourServices?.map((service) => (
                                <Grid key={service._id} item xs={12} sm={6} md={4} lg={3}>
                                    <Box
                                        sx={{
                                            cursor: 'pointer'
                                        }}
                                    >
                                        <div onClick={() => navigateToServiceDetails(service)}>
                                            <img src={require(`../../../Assets/Services/${service.name}.png`)} alt="" width="120" height="120"/>
                                            <Typography>{service.name}</Typography>
                                        </div>
                                    </Box>
                                    {/* <Stack>
                                        <Paper elevation='0'>
                                            
                                        </Paper>
                                    </Stack> */}
                                </Grid>
                            ))
                        }
                    </Grid>
                </Container> : <></>
            }
        </Box>
    );
};

export default Services;