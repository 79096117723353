import React from 'react';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import { GET } from '../../../Common/utils';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    '&:not(:last-child)': {
        borderBottom: 0,
    },
    '&::before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor:
        theme.palette.mode === 'dark'
            ? 'rgba(255, 255, 255, .05)'
            : 'rgba(0, 0, 0, .03)',
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

const ServiceDetails = () => {
    const { state } = useLocation();
    const categoryData = state.categoryData;
    const [expanded, setExpanded] = useState([]);
    const [treatmentData, setTreatmentData] = useState('');
    
    useEffect(() => {
        response();
    }, []);

    const response = () => {
        let requestData = `categoryId=${categoryData.uid}`
        return new Promise((resolve, reject) => {
            GET("FETCH_ALL_SERVICES", requestData, (res) => {
                if (res.data.statusCode == 200) {
                    let responseData = res?.data?.data?.data;
                    setTreatmentData(responseData);
                    let array = new Array(responseData?.length);
                    array.fill(false);
                    array[0] = true
                    setExpanded(array);
                }
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }

    const handleChange = (index) => (event, newExpanded) => {
        const newArr = [...expanded];
        for(let i = 0; i < newArr.length; i++) {
            if(i == index) {
                newArr[i] = newExpanded;
            } else {
                newArr[i] = false;
            }
        }
        setExpanded(newArr);
    };

    return (
        <Box sx={{ p: 2, mb: 2 }}>
            {
                treatmentData.length > 0 ?
                    <Container>
                        <Typography sx={{ mt: 2, mb: 2, fontWeight: 600, textAlign: "center" }}
                            variant='h4'
                        >{treatmentData[0].categoryName}
                        </Typography>
                        {
                            treatmentData?.map((treatment, index) => (
                                <div key={treatment.uid}>
                                    <Accordion expanded={expanded[index]} onChange={handleChange(index)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography variant='h5'>{treatment.name}</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            {
                                                treatment.description ? 
                                                    <Typography component='div' variant='title' sx={{mb: { xs: 2, md: 2 }}}>
                                                        {treatment.description}
                                                    </Typography> : ""
                                            }
                                            {
                                                treatment.sections.map((data) => (
                                                    <div key={data._id}>
                                                        <Typography variant='title' sx={{mb: { xs: 2, md: 2 }}}><b>{data.title}:</b></Typography>
                                                        {
                                                            data.values.map((value, i) => (
                                                                <List key={i} sx={{ listStyleType: 'disc', pl: 4 }}>
                                                                    <ListItem sx={{ display: 'list-item', padding:0 }}>{value}</ListItem>
                                                                </List>
                                                            ))
                                                        }
                                                        <br></br>
                                                    </div>
                                                ))
                                            }
                                        </AccordionDetails>
                                    </Accordion>
                                </div>
                            ))
                        }
                    </Container> : <></>
            }
        </Box>
    );
};

export default ServiceDetails;