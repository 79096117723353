import { Avatar, Grid, LinearProgress, Typography, Container } from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './OurExperts.css';
import { GET } from '../../../Common/utils';

const OurExperts = () => {
    const [experts, setExperts] = useState([])
    const navigate = useNavigate();
    useEffect(() => {
        response();
    }, []);

    const response = () => {
        return new Promise((resolve, reject) => {
            GET("FETCH_ALL_DOCTOR", '', (res) => {
                if(res.data.statusCode == 200) {
                    let responseData = res?.data?.data?.data;
                    setExperts(responseData);
                }
                resolve(res);
            }, (err) => {
                reject(err);
            });
        })
    }
    const truncate = ( str, n ) => {
        if (str.length <= n) { return str; }
        const subString = str.slice(0, n-1); // the original check
        return subString.slice(0, subString.lastIndexOf(" "));
    };

    const navigateTo = (path, doctorData) => {
        navigate(`/doctor/${path}`, { state: { doctorData }});
    }

    return (
        <Box id='doctors' sx={{ bgcolor: '#fff', p: 4, textAlign: "center" }}>
            <Typography sx={{ mt: 2, mb: 1 }} variant='h5'>
                <b>Meet Our Expert Team</b>
            </Typography>

            <Typography sx={{ mb: 4 }}>
                <b>We are committed to ensure you the best service</b>
            </Typography>

            {
                experts?.length > 0 && <Grid container spacing={3}>

                    {
                        experts?.map((doctor) => (
                            <Grid key={doctor._id} item xs={12} sm={6} md={4} lg={3} sx={{ mx: 'auto' }} className='flip-box'>
                                <div className="flip-box">
                                    <div className="flip-box-inner">
                                        <div className="flip-box-front">
                                            <Avatar src={doctor.profilePic ? require(`../../../Assets/Doctors/${doctor.profilePic}.png`) : ""} sx={{ width: '7rem', height: '7rem', mb: 0, mx: 'auto', my: 2 }} />
                                            <Typography variant='subtitle' fontWeight="bold">{ doctor.name }</Typography>
                                            <Typography variant='body2' fontWeight="bold" sx={{mt: 1}}>{ doctor.category }</Typography>
                                        </div>
                                        <div className="flip-box-back flip-box">
                                            {/* <h4>{ doctor.specialize }</h4> */}
                                            <p className='expert-description'>{ truncate(doctor.shortDescription, 190) }</p>
                                            <p onClick={() => navigateTo(doctor.name, doctor)} className='red--text' style={{cursor: 'pointer'}}>Read more...</p>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        ))
                    }
                </Grid>
            }
        </Box>
    );
};

export default OurExperts;