import React, { useEffect, useState } from 'react'
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import HomeIcon from '@mui/icons-material/Home';
import { emphasize, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Tab from '@mui/material/Tab';
import { Typography } from '@mui/material';

const StyledBreadcrumb = styled(Chip)(({ theme }) => {
    const backgroundColor =
        theme.palette.mode === 'light'
            ? theme.palette.grey[100]
            : theme.palette.grey[800];
    return {
        backgroundColor,
        height: theme.spacing(3),
        color: theme.palette.text.primary,
        fontWeight: theme.typography.fontWeightRegular,
        '&:hover, &:focus': {
            backgroundColor: emphasize(backgroundColor, 0.06),
        },
        '&:active': {
            boxShadow: theme.shadows[1],
            backgroundColor: emphasize(backgroundColor, 0.12),
        },
        cursor: 'pointer'
    };
});

const UserProfile = () => {
    const [value, setValue] = useState('1');

    const handleChange = (event, newValue) => {
        event.preventDefault();
        setValue(newValue);
    };
    useEffect(() => {
        // fetchUser();
    }, []);
    function stringToColor(string) {
        let hash = 0;
        let i;

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
        }

        let color = '#';

        for (i = 0; i < 3; i += 1) {
            const value = (hash >> (i * 8)) & 0xff;
            color += `00${value.toString(16)}`.slice(-2);
        }
        /* eslint-enable no-bitwise */

        return color;
    }

    function stringAvatar(name) {
        let words = name.split(' ');
        return {
            sx: {
                bgcolor: stringToColor(name),
            },
            children: words.length > 1 ? `${words[0][0]}${words[1][0]}` : `${words[0][0]}`,
        };
    }

    function handleClick(event) {
        event.preventDefault();
    }

    return (
        <Container sx={{ pt: '5vh', pb: '5vh' }} maxWidth='sm'>
            <div role="presentation" onClick={handleClick}>
                {/* <Breadcrumbs aria-label="breadcrumb">
                    <StyledBreadcrumb
                        component="a"
                        href="/"
                        label="Home"
                        icon={<HomeIcon fontSize="small" />}
                    />
                    <StyledBreadcrumb component="a" href="#" label="User Profile" />
                </Breadcrumbs> */}
            </div>
            <Box sx={{ width: '100%', typography: 'body1' }}>
                <TabContext value={value}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <TabList onChange={handleChange} centered aria-label="lab API tabs example">
                            <Tab label="Report" value="2" />
                            <Tab label="Profile" value="1" />
                        </TabList>
                    </Box>
                    <TabPanel value="1">
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                alignItems: 'center'
                            }}
                        >
                            <Stack direction="row" spacing={2}>
                                <Avatar {...stringAvatar('Naari')} sx={{ width: '8rem', height: '8rem' }} />
                            </Stack>
                            <h4>Welcome Naari</h4>
                            {/* <Typography variant='subtitle'>Welcome Hemant</Typography> */}
                        </Box>
                        {/* <Grid container rowSpacing={{ xs: 2, md: 3 }} columnSpacing={2}>
                            <Grid item sx={12} md={6}>
                                <Typography>Hemant</Typography>
                            </Grid>
                            <Grid item sx={12} md={6}>
                                <Typography>Hemant</Typography>
                            </Grid>
                        </Grid> */}
                    </TabPanel>
                    <TabPanel value="2">
                        <p>Work In Progress....</p>
                    </TabPanel>
                </TabContext>
            </Box>
        </Container>
    )
}

export default UserProfile