import { Button, Container, TextField, Typography } from '@mui/material';
import { Box, width } from '@mui/system';
import React from 'react';
import Services from './Treatment/Treatment';
import Banner from './Banner/Banner';
import './Home.css'
import OurExperts from './OurExperts/OurExperts';
import Whyus from './Whyus/Whyus';

const Home = () => {
    return (
        <div id='home' className='header-title'>
            <Banner></Banner>
            <Whyus></Whyus>
            {/* <Container className='content-container' maxWidth="xl">
                <About></About>
            </Container> */}
            <OurExperts></OurExperts>
            <Services></Services>
        </div>
    );
};

export default Home;