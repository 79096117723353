import React from 'react';
import { Box, Grid, Typography } from '@mui/material'
const Blog = () => {
    return (
        <Grid container justifyContent="center" sx={{pl: 2, pr: 2, pt: '5vh', pb: '5vh'}}>
            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>Embrace Fertility: Nurturing Dreams, Conquering Fibroids & Polyps</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/embrace_fertility_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                    Welcome to Naari Clinic, where we strive to empower individuals on their journey towards parenthood. In this blog post, we delve into the complexities of fibroids and polyps, shedding light on how these conditions may impact fertility and the comprehensive services we offer to support your path to conception.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='body2'>
                        <b>1. What Are Fibroids and Polyps?</b><br></br>
                        Fibroids and polyps are noncancerous growths that can develop in the uterus, potentially affecting fertility. Fibroids are muscular tumors that can distort the uterine cavity, while polyps are small, finger-like growths that can protrude into the uterus.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>2. Impact on Fertility:</b><br></br>
                        Both fibroids and polyps can interfere with fertility by disrupting the normal functioning of the reproductive organs. They may affect implantation, hinder embryo development, or even cause recurrent miscarriages. Understanding the specific challenges these conditions pose is crucial on the path to parenthood.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>3. Diagnostic Procedures:</b><br></br>
                        At Naari Clinic, we utilize advanced diagnostic procedures to identify and assess the presence of fibroids and polyps. Our experienced team employs state-of-the-art technology to provide accurate and comprehensive evaluations, Using 3D and 4D Ultrasonography and taking help is saline infusion sonography’s when in need. ensuring a personalized approach to your fertility journey.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>4. Treatment Options:</b><br></br>
                        We offer a range of cutting-edge treatment options tailored to your unique needs. From minimally invasive procedures to fertility-preserving surgeries, our expert team is dedicated to providing compassionate care and effective solutions for fibroids and polyps.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Conclusion</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                    Navigating fertility challenges can be overwhelming, but you're not alone. At Naari Clinic, we are committed to supporting you every step of the way. If you suspect fibroids or polyps may be impacting your fertility, schedule a consultation with our specialists to explore personalized treatment options.<br></br><br></br><br></br><br></br>
                    </Typography>
                </Box>
            </Grid>

            
            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>Unlocking the Mystery: Understanding Ovarian Cysts and Our Dedicated Services</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/ovarian_cysts_website_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Embarking on a journey to understand and manage ovarian cysts requires reliable information and comprehensive support. 
                        Our latest blog is a guide to demystify ovarian cysts and shed light on the specialized services we offer to ensure your well-being.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='body2'>
                        <b>1.Decoding Ovarian Cysts:</b><br></br>
                        Explore the world of ovarian cysts, understanding their types, causes, and common symptoms. We unravel the mystery behind these fluid-filled sacs and their impact on reproductive health.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>2.Diagnostic Precision:</b><br></br>
                        Learn about the advanced diagnostic tools and techniques our clinic employs to accurately identify and assess ovarian cysts. Early detection is key to effective management.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>3.Personalized Treatment Approach:</b><br></br>
                        Discover our patient-centered approach to ovarian cyst management. From watchful waiting to medical intervention or minimally invasive surgery, our services are tailored to your unique needs.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>4.Navigating Reproductive Health:</b><br></br>
                        Explore the connection between ovarian cysts and fertility. Our blog provides insights into how our services are designed to address concerns related to family planning and reproductive well-being.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>5.Preserving healthy ovarian tissue:</b><br></br>
                        Not every surgeon can perform reconstructive surgeries, specially in women with infertility preserving healthy tissue is more important than removing unhealthy tissue. 
                        Special surgical instruments like micro cautery and laser robotic surgeries come to rescue while handling this kind of cases. Our doctor has been performing fertility preserving surgery for more than a decade and has expertise in the same.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>Note:</b><br></br>
                        Customize the placeholders like Naari Clinic with the actual name of your clinic or service.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Conclusion</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                    At Naari Clinic, we prioritize your health and well-being. Our ovarian cyst services are a testament to our commitment to providing accurate diagnosis, personalized treatment plans, and ongoing support. 
                    Whether you're seeking information or navigating a diagnosis, let us be your dedicated partner on the path to ovarian health.<br></br><br></br><br></br><br></br>
                    </Typography>
                </Box>
            </Grid>


            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>Navigating Intimacy: Understanding Sexual Dysfunction and Fertility</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/dysfunction_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Embarking on the journey to parenthood involves more than just fertility. Our latest blog sheds light on the often-overlooked aspect of sexual health, addressing both male and female sexual dysfunction. 
                        Discover how our comprehensive services at [Your Fertility Clinic] aim to support couples on their path to a fulfilling and intimate family-building experience.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='body2'>
                        <b>Section 1: Unveiling Male Sexual Dysfunction</b><br></br>
                        Explore the complexities of male sexual dysfunction and its potential impact on fertility. From erectile dysfunction to performance anxiety, our specialists provide insights, assessments, and personalized treatments to address both the physical and emotional aspects of male sexual health.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>Section 2: Decoding Female Sexual Dysfunction</b><br></br>
                        Delve into the realm of female sexual dysfunction and its connection to fertility challenges. Our dedicated team at [Your Fertility Clinic] understands the intricate interplay of factors affecting female sexual health, offering holistic solutions that complement fertility treatments.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <b>Section 3: Integrated Solutions for Couples</b><br></br>
                        Discover how our clinic integrates fertility treatments with specialized care for sexual dysfunction, creating a comprehensive approach tailored to the unique needs of each couple. Our aim is to enhance not only fertility but also the overall intimacy and well-being of our patients.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Conclusion</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        At Naari Clinic, we believe that addressing sexual health is an integral part of the fertility journey. 
                        By providing personalized solutions for both male and female sexual dysfunction, we empower couples to embrace the full spectrum of family-building possibilities. 
                        Learn more about our integrated services and embark on a journey to parenthood that prioritizes both fertility and intimate well-being. <br></br><br></br><br></br><br></br>
                    </Typography>
                </Box>
            </Grid>

            
            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>Breaking the Silence on Vaginismus</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/vaginismus_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Vaginismus is a type of sexual dysfunction, when the pelvic or sometimes vaginal muscle spasms or squeeze when something's entering it like a tampon or a penetrative sex/ penis ( involuntarily or persistently contract ). 
                        Doesn't interfere with sexual arousal.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Types of Vaginismus</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        1. <b>Primary: </b> When a woman has had pain every time she tried inserting something into her vagina and they've never been able to insert anything. It's also called life long Vaginismus.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        2. <b>Secondary: </b> When a woman has had sex before without pain but then it becomes difficult or impossible. It's called acquired Vaginismus.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Signs and Symptoms</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        <ul>
                            <li>Fear of sex or pain</li>
                            <li>Loss of sexual desire</li> 
                            <li>Not being able to have penetrative sex</li> 
                            <li>Difficult tampon insertion</li>
                            <li>Often report a burning or stinging pain when they insert something into vagina</li>
                        </ul>
                        These symptoms are involuntary and they cannot get better without treatment.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Causes</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        It's usually linked to anxiety or fear of having sex. But it's not always clear which came first, the vaginismus or the anxiety. 
                        Some women have vaginismus in all situations and with any object. Others have it only in certain cases, like with one partner but not others. 
                        Or they might have it with sexual intercourse but not with tampons or during medical exams. Other health problems like infections and dryness can also cause painful intercourse and Vaginismus is misdiagnosed as dyspareunia and post menopausal atrophy.
                        It's important to see a doctor to find out what's causing it. <br></br><br></br><br></br><br></br>
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>Celebrating New Born Care Week: A Guide to Nurturing the Tiniest Blossoms</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/new_born_care_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        As we embrace the significance of New Born Care Week, we dedicate this blog to the precious moments and essential care that newborns deserve. 
                        Join us on this journey as we explore invaluable insights into nurturing the tiniest members of our families. <br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>The First Touch: Skin-to-Skin Bonding</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        The magic of skin-to-skin contact goes beyond warmth. Discover the profound impact of this early bonding method on both newborns and parents. 
                        Learn how it fosters a sense of security and lays the foundation for a strong parent-child relationship.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Feeding Essentials: Breastfeeding and Beyond</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Uncover the wonders of breastfeeding and the importance of providing the best nutrition for your newborn. 
                        Our experts share tips on successful breastfeeding, addressing common concerns, and the significance of a well-balanced diet for nursing mothers.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Sleep, Safety, and Serenity: Creating the Ideal Environment</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Explore the art of creating a safe and serene space for your newborn. 
                        From sleep routines to safety measures, we guide you through the essential elements of establishing a comfortable environment that promotes healthy development.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Navigating Newborn Health: From Check-ups to Immunizations</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        Understanding the health needs of your newborn is paramount. Delve into the recommended check-ups, vaccinations, and milestones to track during the initial months. 
                        Stay informed on how our clinic supports you in ensuring your newborn's well-being.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Expert Consultation: Your Partner in Parenthood</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        New Born Care Week is the perfect time to schedule a consultation with our experienced gynecologists and pediatric experts. 
                        Discover the range of services and support we offer to guide you through this exciting journey of parenthood.<br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Conclusion</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        This New Born Care Week, let's celebrate the joy and responsibility that comes with welcoming a new life. 
                        Our blog is here to serve as your companion, providing insights, advice, and support as you embark on the incredible adventure of caring for your newborn. 
                        Happy New Born Care Week!  <br></br><br></br><br></br><br></br>
                    </Typography>
                </Box>
            </Grid>

            <Grid item xs={12} sm={7}>
                <Typography component='div' variant='h5' color='#fec32b'>
                    <b>A Glimpse into the World of IVF: Fertility Treatment Beyond Expectation</b>
                </Typography>
                <Box sx={{pt: 2, pb: 1}}>
                    <img src={require(`../../Assets/Blogs/ivf_blog.jpg`)} style={{height: "50vh", width: "100%"}} />
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Introduction</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        In recent years, assisted reproductive technologies have revolutionized the way we approach fertility challenges. 
                        Among these, In Vitro Fertilization, commonly known as IVF, has gained significant popularity. 
                        This groundbreaking procedure has brought hope and joy to countless individuals and couples struggling to conceive. 
                        In this blog, we will delve into the world of IVF, shedding light on what it entails, who it can help, 
                        and some of the ethical considerations surrounding this remarkable fertility treatment. <br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1'  color='#fec32b'>
                        <b>Understanding IVF</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        In Vitro Fertilization (IVF) is a medical procedure that involves the fertilization of an egg by sperm outside the human body. 
                        The process typically consists of the following steps: <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        1. <b>Ovulation Induction:</b> In this stage, the woman is given hormonal medications to stimulate the ovaries to produce multiple eggs. 
                        Regular monitoring ensures the eggs are maturing properly. <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        2. <b>Egg Retrieval: </b> Once the eggs are mature, a minor surgical procedure is performed to retrieve them from the ovaries. 
                        This procedure is minimally invasive and is done under anesthesia.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        3.	<b>Fertilization:</b> The retrieved eggs are combined with sperm in a laboratory dish. 
                        The fertilized eggs, now called embryos, are monitored for several days. <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        4.	<b>Embryo Transfer:</b> One or more healthy embryos are selected and transferred into the woman's uterus. <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        5.	<b>Pregnancy Test:</b> A pregnancy test is performed about 10-14 days after the embryo transfer to determine if the procedure was successful. <br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Who Benefits from IVF?</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        IVF is a versatile fertility treatment that can help a range of individuals and couples facing various challenges. It is often recommended for: <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        1. <b>Couples with Unexplained Infertility: </b> When no apparent cause of infertility can be identified.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        2.	<b>Women with Blocked or Damaged Fallopian Tubes:</b> IVF bypasses the need for the fallopian tubes in fertilization.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        3.	<b>Male Infertility Issues:</b> IVF can overcome sperm-related issues.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        4.	<b>Advanced Maternal Age:</b> For women who wish to delay motherhood. <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        5.	<b>Genetic Disorders:</b> Couples with a history of genetic disorders may opt for pre-implantation genetic testing to select healthy embryos. <br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Ethical Considerations</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        While IVF has been a beacon of hope for many, it also raises ethical questions. Some of the key ethical considerations include: <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        1. <b>Embryo Disposition:  </b> What should be done with unused embryos? Couples often face the dilemma of whether to freeze, donate, or discard them.<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        2.	<b>Selective Reduction:</b> : In cases of multiple pregnancies, should selective reduction be considered to improve the health of the mother and remaining embryos?<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        3.	<b>Designer Babies:</b> The ability to screen and select embryos for certain genetic traits sparks debates about the ethical implications of "designer babies."<br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        4.	<b>Cost and Access:</b> The cost of IVF and disparities in access to fertility treatments pose ethical challenges, as not everyone can afford these procedures. <br></br><br></br>
                    </Typography>
                </Box>
                <Box>
                    <Typography component='div' variant='subtitle1' color='#fec32b'>
                        <b>Conclusion</b>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        In Vitro Fertilization is a remarkable medical advancement that has changed the lives of countless people by helping them achieve their dream of parenthood. 
                        It is a complex procedure that requires careful consideration and consultation with healthcare professionals. 
                        While it presents ethical dilemmas, it has offered new hope for those facing infertility. <br></br><br></br>
                    </Typography>
                    <Typography component='div' variant='body2'>
                        As the field of reproductive medicine continues to evolve, it is essential to maintain open discussions on the ethical and societal implications of these procedures to ensure that they are used responsibly and ethically. 
                        IVF, with all its complexities, has undoubtedly opened new doors to parenthood for many and will continue to do so in the future. <br></br><br></br>
                    </Typography>
                </Box>
            </Grid>
        </Grid>
    )
}

export default Blog;