
import { Routes } from 'react-router-dom';
import { Route } from 'react-router-dom';
import { BrowserRouter } from 'react-router-dom';
import './App.css';
import About from './components/About/About';
import Header from './components/Header/Header/Header';
import Home from './components/Home/Home';
import { ThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import Notfound from './components/Notfound/Notfound';
import Footer from './components/Header/Footer/Footer';
import Services from './components/Home/Treatment/Treatment.js';
import Doctors from './components/Services/Doctors/Doctors';
// import Appointment from './components/Services/Appointment/Appointment';
import ServiceDetails from './components/Services/ServiceDetails/ServiceDetails';
import DoctorDetails from './components/Services/Doctors/Doctors.js'
import Blog from './components/Blog/Blog';
import UserProfile from './components/User/UserProfile.js';
import Appointment from './components/Services/Appointment/Appointment.js';
import ScrollToTop from './components/HelperComponents/SCrollToTop.js';
import { SnackbarProvider } from 'notistack';

// custom style for this application
export const myTheme = createTheme({
    palette: {
        primary: {
            main: '#fec32b',
        },
        secondary: {
            main: '#f48fb1',
        },
        alternate: {
            main: '#fff',
        },
        text: {
            secondary: '#212121',
        },
    },
    typography: {
        fontFamily: "Quicksand",
        fontWeightLight: 400,
        fontWeightRegular: 500,
        fontWeightMedium: 600,
        fontWeightBold: 700,
    }

});

function App() {
    return (
        <>
            {/* <Authprovider> */}
            <ThemeProvider theme={myTheme}>
                <SnackbarProvider anchorOrigin={{ horizontal: 'center', vertical: 'top' }}>
                    <BrowserRouter>
                        <Header></Header>
                        <ScrollToTop />
                        <Routes>
                            <Route exact path='/' Component={Home}></Route>
                            <Route path='/#home' Component={Home}></Route>
                            <Route path='/#doctors' Component={Doctors}></Route>
                            <Route path='/#about' Component={About}></Route>
                            <Route path='/blog' Component={Blog}></Route>
                            <Route path='/#services' Component={Services}></Route>
                            <Route exact path='/doctor/:id' Component={DoctorDetails}></Route>
                            <Route exact path='/profile' Component={UserProfile}></Route>
                            <Route exact path='/appointment' Component={Appointment}></Route>
                            <Route exact path='/treatment' Component={ServiceDetails}></Route>
                            <Route path='*' Component={Notfound}></Route>
                        </Routes>
                        <Footer></Footer>
                    </BrowserRouter>
                </SnackbarProvider>
            </ThemeProvider>
            {/* </Authprovider> */}
        </>

    );
}

export default App;
