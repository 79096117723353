"use strict";
import axios from 'axios';
import * as URL from './API_Config';

const getURL = (URL_TYPE) => {
    var url = URL[URL_TYPE];
    return url;
}

const authHeader = () => {
    let headerObj = {
        headers: {
            "Content-Type": "application/json",
            Authorization: "",
        },
    };
    return headerObj;
}

export const GET = (apiUrl, requestData, successfulCallback, errorCallback, sign="?") => {
    let url = getURL(apiUrl);

    if (requestData) {
        url = url + sign + requestData;
    }
    axios
        .get(url, authHeader())
        .then((response) => {
            successfulCallback(response);
        })
        .catch((error) => {
            console.log("error of GET utils is ", error);
            errorCallback(error);
        });
}

export const POST = (apiUrl, requestBody, successfulCallback, errorCallback) => {
    let url = getURL(apiUrl);
    axios
        .post(url, requestBody, authHeader())
        .then((response) => {
            successfulCallback(response);
        })
        .catch((error) => {
            // console.log("error of GET utils is ", error);
            errorCallback(error);
        });
}

export const POST_ByURL = (apiUrl, requestBody, successfulCallback, errorCallback, updateId) => {
    let url = getURL(apiUrl);
    if (updateId) {
        url = url + '/' + updateId;
    }
    axios
        .post(url, requestBody, authHeader())
        .then((response) => {
            successfulCallback(response);
        })
        .catch((error) => {
            console.log("error of GET utils is ", error);
            errorCallback(error);
        });
}

export const PUT = (apiUrl, api_id, requestBody, successfulCallback, errorCallback) => {
    let url = getURL(apiUrl) + api_id;
    axios
        .put(url, requestBody, authHeader())
        .then((response) => {
            successfulCallback(response);
        })
        .catch((error) => {
            console.log("error of GET utils is ", error);
            errorCallback(error);
        });
}