const BASE_URL = 'https://allaboutnaari.com/';
const USER_URL = BASE_URL + 'user/';
const ADMIN_BASE_URL = 'https://admin.allaboutnaari.com/';
const DOCTOR_URL = ADMIN_BASE_URL + 'doctor/';
const APPOINTMENT_URL = BASE_URL + 'appointments/';
const REGISTER = USER_URL + 'register';
const LOGIN = USER_URL + 'login';
const FETCH_ALL_USER_PROFILE = USER_URL + 'fetch-all';
const FETCH_SINGLE_USER_PROFILE = USER_URL + 'fetch/';
const BOOK_APPOINTMENT = APPOINTMENT_URL + 'book-appointment';
const FETCH_AVAILABLE_SLOTS = APPOINTMENT_URL + 'get-available-slots';
const FETCH_ALL_APPOINTMENT = APPOINTMENT_URL + 'fetch-all';
const FETCH_SINGLE_APPOINTMENT = APPOINTMENT_URL + 'fetch/';
const FETCH_ALL_DOCTOR = DOCTOR_URL + 'fetch-all';
const SERVICES_URL = ADMIN_BASE_URL + 'services/';
const FETCH_ALL_SERVICES = SERVICES_URL + 'fetch-services';
const FETCH_ALL_CATEGORIES = SERVICES_URL + 'fetch-service-categories';
module.exports = {
    APPOINTMENT_URL,
    REGISTER,
    LOGIN,
    FETCH_ALL_USER_PROFILE,
    FETCH_SINGLE_USER_PROFILE,
    BOOK_APPOINTMENT,
    FETCH_AVAILABLE_SLOTS,
    FETCH_ALL_APPOINTMENT,
    FETCH_SINGLE_APPOINTMENT,
    FETCH_ALL_DOCTOR,
    FETCH_ALL_SERVICES,
    FETCH_ALL_CATEGORIES
}