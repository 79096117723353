import React from 'react';
import { Avatar, Box, Container, Divider, Grid, Stack, styled, Typography, Fab } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import YouTubeIcon from '@mui/icons-material/YouTube';
import PhoneCallbackIcon from '@mui/icons-material/PhoneCallback';
import './Footer.css';
import { pink } from '@mui/material/colors';
import { HashLink } from 'react-router-hash-link';
// import LogoImage from '../../../Assets/logo1.png';
import Map from '../../Map/Map';

// const Root = styled('div')(({ theme }) => ({
//     width: '100%',
//     ...theme.typography.body2,
//     '& > :not(style) + :not(style)': {
//         marginTop: theme.spacing(2),
//     },
// }));

// copyright function for generate year automatically
function Copyright(props) {
    return (
        <Box sx={{ bgcolor: '#fec32b', py: 3, bottom: 0}}>
            <Typography variant="body2" color="#000" align="center" {...props}>
                {'Copyright © All About Naari | All Rights Reserved 2023'}
                {/* {new Date().getFullYear()} */}
                {'.'}
            </Typography>
        </Box>
    );
}

let size = window.innerWidth;

const Footer = () => {
    return (
        <footer>
            <Box id='contact-us' sx={{ bgcolor: '#304146', color: 'text.secondary', pb: 2, top: 'auto', pt: 5 }}>
                <Container maxWidth="xl">
                    <Grid container
                        spacing={{ xs: 1, md: 2 }}>
                        <Grid item xs={12} sm={5} md={5} sx={{p:2}}>
                            <Box>
                                {/* <Map /> */}
                                <img src={require(`../../../Assets/${size > 900 ? "address.png" : "address_mob.png"}`)} alt="" style={{height: "50vh", width: "100%"}}/>
                            </Box>
                        </Grid>
                        <Grid item xs={12} sm={2} md={2} sx={{p:2}}>
                            <Box>
                                <Typography variant='h6' className='text-style'>Address</Typography>
                                <Typography className='text-style'>
                                    H.No. 6-3-347/8A, G2 , Sunny Residency, Dwarakapuri colony, Punjagutta, Hyderabad, Telangana 500082, INDIA.
                                </Typography>
                            </Box>
                        </Grid>
                        {/* ----------service part ---------------*/}
                        <Grid item xs={12} sm={2}>

                            <Box sx={{ p: 1 }}><HashLink className='text-style' to='/#home' color='inherit'>Home</HashLink></Box>
                            <Box sx={{ p: 1 }}><HashLink className='text-style' to='/#why-us' color='inherit'>Why to choose us </HashLink></Box>
                            <Box sx={{ p: 1 }}><HashLink className='text-style' to='/#doctors' color='inherit'>Doctors</HashLink></Box>
                            <Box sx={{ p: 1 }}><HashLink className='text-style' to='/#services' color='inherit'>Services</HashLink></Box>
                        </Grid>

                        {/* ----------social media part ------------*/}

                        <Grid item xs={12} sm={3}>
                            <Typography variant='h6' className='text-style'>Contact Us</Typography>
                            <Box sx={{ display: 'flex', alignItems: 'center' }} marginTop={2}>
                                <EmailIcon fontSize='medium' sx={{ color: '#EDEADE'}} />
                                <Typography variant='body2' className='text-style' color='inherit' marginLeft={0.5}>
                                    info@allaboutnaari.com
                                </Typography>
                            </Box>
                            <Box sx={{ display: 'flex', alignItems: 'center', my: 2 }}>
                                <PhoneIcon fontSize='medium' sx={{ color: '#EDEADE'}} />
                                <Typography variant='body2' className='text-style' color='inherit' marginLeft={0.5}>
                                    +91-9121069624
                                </Typography>
                            </Box>
                            <div className='social-media'>
                                <a href='https://www.facebook.com/AllAboutNaari' target='_blank'>
                                    <Fab size="small" sx={{ mb:1, bgcolor: '#4267B2 !important', color: '#fff', transition: '0.5s all ease-in-out', ':hover': {transform: 'scale(1.2)'} }}>
                                        <FacebookIcon  />
                                    </Fab>
                                </a>
                                <a href='https://www.instagram.com/all.about.naari/' target='_blank'>
                                    <Fab size="small" sx={{ mb:1, bgcolor: '#d62976 !important', color: '#fff', transition: '0.5s all ease-in-out', ':hover': {transform: 'scale(1.2)'} }}>
                                        <InstagramIcon />
                                    </Fab>
                                </a>
                                <a href='https://www.youtube.com/channel/UCdZuE2P0VzmAqWpgcUowXzA' target='_blank'>
                                    <Fab size="small" sx={{ mb:1, bgcolor: '#CD201F !important', color: '#fff', transition: '0.5s all ease-in-out', ':hover': {transform: 'scale(1.2)'} }}>
                                        <YouTubeIcon />
                                    </Fab>
                                </a>
                                <a href="tel:+91-9121069624">
                                    <Fab size="small" sx={{ mb:1, bgcolor: '#0072b1 !important', color: '#fff', transition: '0.5s all ease-in-out', ':hover': {transform: 'scale(1.2)'} }}>
                                        <PhoneCallbackIcon />
                                    </Fab>
                                </a>
                                {/* <Fab size="small" sx={{ mb:1, bgcolor: '#0072b1 !important', color: '#fff', transition: '0.5s all ease-in-out', ':hover': {transform: 'scale(1.2)'} }}>
                                    <PhoneCallbackIcon />
                                </Fab> */}
                            </div >
                        </Grid>
                    </Grid>
                    {/* <Divider light sx={{ mb: 2, backgroundColor: 'grey' }} /> */}
                </Container>
                <Copyright />
            </Box>

        </footer >
    );
};

export default Footer;