import React, { useState } from 'react'
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';
import axios from 'axios';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import MuiPhone from './InternationalPhoneInput';
import { POST } from '../../Common/utils';
import { requiredField } from '../../Common/validationRules';
import { ThemeProvider, createTheme } from '@mui/material';
import { useSnackbar } from 'notistack';


export default function Register({ handleClose }) {
    const [showRegPassword, setRegShowPassword] = useState(false);
    const [showRegPassword1, setRegShowPassword1] = useState(false);
    const [erroeMessage, setErroMessage] = useState({});
    const [registrationBody, setRegistrationBody] = useState({
        firstName: '',
        lastName: '',
        userName: '',
        password: '',
        confirmPassword: '',
        email: '',
        mobileNumber: '',
        isdCode: '',
        dob: '',
        gender: '',
    });
    const [country, setCountry] = useState({});
    const { enqueueSnackbar } = useSnackbar();

    const handleClickShowRegPassword = (e) => setRegShowPassword((show) => !show);
    const handleClickShowRegConfirmPassword = (e) => setRegShowPassword1((show) => !show);

    const validateForm = (body) => {
        const errors = {};
        if (!body.firstName) {
            errors.firstName = 'First name is required';
        }
        if (!body.lastName) {
            errors.lastName = 'Last name is required';
        }
        if (body.userName.length <= body.isdCode.length) {
            errors.userName = 'Username is required';
        }
        // if (!body.email) {
        //     errors.email = 'Email is required';
        // }
        if (!body.password) {
            errors.password = 'Password is required';
        }
        if (!body.confirmPassword) {
            errors.confirmPassword = 'Confirm password is required';
        } else if(body.password != body.confirmPassword) {
            errors.confirmPassword = 'Confirm password should match the password.'
        }
        return errors;
    }

    const handleRegistration = (incomingData, incomingField, country={}) => {
        console.log('incoming data in register', incomingData, country)
        setRegistrationBody((prevState) => {
            return {
                ...prevState,
                [incomingField]: incomingData
            }
        })
        if(incomingField == 'userName') {
            setCountry(country);
            setRegistrationBody((prevState) => {
                return {
                    ...prevState,
                    ['isdCode']: `+${country.dialCode}`,
                }
            })
        }
        console.log("registration data", registrationBody, incomingData, incomingField);
    }

    const submitRegister = async (e) => {
        e.preventDefault();
        let dialCode = `+${country.dialCode}`;
        let dialCodeLength = dialCode.length;
        let mobileNumber = registrationBody.userName.substring(dialCodeLength);
        setRegistrationBody((prevState) => {
            return {
                ...prevState,
                ['mobileNumber']: mobileNumber,
            }
        })
        let error = await validateForm(registrationBody);
        setErroMessage(error);
        if(Object.keys(error).length == 0) {
            let response = () => {
                return new Promise((resolve, reject) => {
                    POST('REGISTER', registrationBody, (res) => {
                        handleClose();
                        resolve();
                        enqueueSnackbar(res.data.msg, { variant: res.data.statusCode == 200 ? 'success' : 'warning' });

                    }, (err) => {
                        console.log(err);
                        reject();
                    });
                })
            }
            let responsedata = await response();
        } else {
            enqueueSnackbar('Please enter mandatory fields', { variant: 'warning' });
            console.log("mobile", registrationBody, mobileNumber);
            setTimeout(() => {
                setErroMessage({});
            }, 5000);
        }
    }
    return (
        <div>
            <Grid container rowSpacing={{ xs: 2, md: 3 }} columnSpacing={2}>
                <Grid item xs={12} md={6}>
                    <TextField
                        autoFocus
                        id="login-firstName"
                        label="First Name *"
                        value={registrationBody.firstName}
                        onChange={(e) => handleRegistration(e.target.value, 'firstName')}
                        error={erroeMessage.firstName ? true : false}
                        helperText={erroeMessage.firstName}
                        type="text"
                        size='small'
                        fullWidth
                        autoComplete="current-password"
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        id="login-lastName"
                        label="Last Name *"
                        value={registrationBody.lastName}
                        onChange={(e) => handleRegistration(e.target.value, 'lastName')}
                        error={erroeMessage.lastName ? true : false}
                        helperText={erroeMessage.lastName}
                        type="text"
                        size='small'
                        fullWidth
                        autoComplete="current-password"
                    />
                </Grid>
                {/* <Grid item xs={12} md={12}>
                <TextField
                    id="login-username"
                    label="Username"
                    value={registrationBody.userName}
                    onChange={(e) => handleRegistration(e.target.value, 'userName')}
                    type="text"
                    size='small'
                    fullWidth
                    autoComplete="current-password"
                />
            </Grid> */}
                {/* <Grid item xs={3} md={3}>
                <FormControl fullWidth size="small">
                    <InputLabel id="login-isd">ISD code</InputLabel>
                    <Select
                        labelId="login-isd"
                        id="login-isd-code"
                        value={registrationBody.isdCode}
                        onChange={(e) => handleRegistration(e.target.value, 'isdCode')}
                        label="ISD code"
                        size='small'
                    >
                        <MenuItem value={+91}>+91</MenuItem>
                        <MenuItem value={+40}>+40</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}
                <Grid item xs={12}>
                    <MuiPhone
                        value={registrationBody.userName}
                        onChange={(value, country) => handleRegistration(value, 'userName', country)}
                        helperText={erroeMessage.userName}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="login-email"
                        label="Email"
                        value={registrationBody.email}
                        onChange={(e) => handleRegistration(e.target.value, 'email')}
                        // error={erroeMessage.email ? true : false}
                        // helperText={erroeMessage.email}
                        type="text"
                        size='small'
                        fullWidth
                        autoComplete="current-password"
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="login-password"
                        label="Password *"
                        value={registrationBody.password}
                        onChange={(e) => handleRegistration(e.target.value, 'password')}
                        type={showRegPassword ? 'text' : 'password'}
                        error={erroeMessage.password ? true : false}
                        helperText={erroeMessage.password}
                        size='small'
                        fullWidth
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRegPassword}
                                        edge="end"
                                    >
                                        {showRegPassword ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                <Grid item xs={12} md={12}>
                    <TextField
                        id="login-password"
                        label="Confirm Password *"
                        value={registrationBody.confirmPassword}
                        onChange={(e) => handleRegistration(e.target.value, 'confirmPassword')}
                        type={showRegPassword1 ? 'text' : 'password'}
                        error={erroeMessage.confirmPassword ? true : false}
                        helperText={erroeMessage.confirmPassword}
                        size='small'
                        fullWidth
                        autoComplete="current-password"
                        InputProps={{
                            endAdornment:
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowRegConfirmPassword}
                                        edge="end"
                                    >
                                        {showRegPassword1 ? <Visibility /> : <VisibilityOff />}
                                    </IconButton>
                                </InputAdornment>
                        }}
                    />
                </Grid>
                {/* <Grid item xs={12} md={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="DOB"
                            slotProps={{ textField: { size: 'small', fullWidth: true } }}
                            value={registrationBody.dob}
                            onChange={(neaValue) => handleRegistration(neaValue, 'dob')}
                            format='YYYY-MM-DD'
                            disableFuture
                        />
                    </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
                <FormControl fullWidth size="medium">
                    <InputLabel id="login-gender">Gender</InputLabel>
                    <Select
                        labelId="login-gender"
                        id="login-mno"
                        value={registrationBody.gender}
                        onChange={(e) => handleRegistration(e.target.value, 'gender')}
                        label="Age"
                        size='small'
                    >
                        <MenuItem value='Female'>Female</MenuItem>
                        <MenuItem value='Male'>Male</MenuItem>
                    </Select>
                </FormControl>
            </Grid> */}
            </Grid>
            <Box sx={{ display: 'block', textAlign: 'center', marginTop: { xs: 3, md: 5 } }}>
                <Button variant="contained" color='primary' onClick={submitRegister}>
                    Register
                </Button>
            </Box>
        </div>
    )
}
