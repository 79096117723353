
import React, { useState } from 'react'
import { Box, Grid, FormHelperText } from '@mui/material';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MuiPhone from './InternationalPhoneInput';
import { POST } from '../../Common/utils';
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';
// import { useForm } from "react-hook-form"
// import { yupResolver } from "@hookform/resolvers/yup"
// import * as yup from "yup"


export default function Login({handleClose}) {
    const [showPassword, setShowPassword] = useState(false);
    const [loginBody, setLoginBody] = useState({
        userName: '',
        password: '',
    });
    const [errorMessage, setErrorMessage] = useState(false);
    const [country, setCountry] = useState({});
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleLoginUsername = (value, country) => {
        setCountry(country);
        setLoginBody((prevState) => {
            return {
                ...prevState,
                userName: value
            }
        })
    }

    const handleLoginPassword = (e) => {
        const { name, value } = e.target;
        setLoginBody((prevState) => {
            return {
                ...prevState,
                password: value
            }
        })
    }

    const validateForm = (body) => {
        const errors = {};
        if (body.userName.length <= `+${country.dialCode}`.length) {
            errors.userName = 'Username is required';
        }
        if (!body.password) {
            errors.password = 'Password is required';
        }
        return errors;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, value } = e.target;
        let error = validateForm(loginBody);
        setErrorMessage(error);
        if(Object.keys(error).length != 0) {
            enqueueSnackbar('Please enter mandatory fields', { variant: 'warning' });
            setTimeout(() => {
                setErrorMessage({});
            }, 5000);
        } else {
            let response = () => {
                return new Promise((resolve, reject) => {
                    POST('LOGIN', loginBody, (res) => {
                        if(res?.data?.statusCode == 200) {
                            window.sessionStorage.setItem('xyz', JSON.stringify(res.data.data.token));
                            handleClose();
                            navigate('/profile');
                        }
                        enqueueSnackbar(res.data.msg, { variant: res.data.statusCode == 200 ? 'success' : 'warning' });
                        resolve();
                    }, (err) => {
                        console.log(err);
                        reject();
                    });
                })
            }
            let responsedata = await response();
            console.log("response data", responsedata);
        }
        
    }

    return (
        <div>
            <form onSubmit={handleSubmit}>
                <Grid container rowSpacing={2}>
                    <Grid item xs={12}>
                        <MuiPhone
                            value={loginBody.userName}
                            onChange={handleLoginUsername}
                            helperText={errorMessage.userName}
                        />
                        {/* <p>{errorMessage.userName}</p> */}
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            id="reg-password"
                            label="Password *"
                            type={showPassword ? 'text' : 'password'}
                            size='small'
                            fullWidth
                            value={loginBody.password}
                            onChange={handleLoginPassword}
                            error={errorMessage.password ? true : false}
                            helperText={errorMessage.password}
                            InputProps={{
                                endAdornment:
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={() => handleClickShowPassword()}
                                            edge="end"
                                        >
                                            {showPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                            }}
                            autoComplete="current-password"
                        />
                    </Grid>
                </Grid>
                <Box sx={{ display: 'block', textAlign: 'center', mt: 2 }}>
                    <Button variant="contained" color='primary' type='submit'>
                        Login
                    </Button>
                </Box>
            </form>
        </div>
    )
}
